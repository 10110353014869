import openNewTab from '../utility/openNewTab';
import { findEngineDriver, getConnectionLabel } from 'dbgate-tools';
import { getAppliedCurrentSchema, getExtensions } from '../stores';
export default function newTable(connection, database) {
    var _a, _b;
    const tooltip = `${getConnectionLabel(connection)}\n${database}`;
    const driver = findEngineDriver(connection, getExtensions());
    openNewTab({
        title: 'Table #',
        tooltip,
        icon: 'img table-structure',
        tabComponent: 'TableStructureTab',
        props: {
            conid: connection._id,
            database,
        },
    }, {
        editor: {
            current: {
                pureName: 'new_table',
                schemaName: (_a = getAppliedCurrentSchema()) !== null && _a !== void 0 ? _a : (_b = driver === null || driver === void 0 ? void 0 : driver.dialect) === null || _b === void 0 ? void 0 : _b.defaultSchemaName,
                columns: [
                    {
                        columnName: 'id',
                        dataType: 'int',
                        isNullable: false,
                        isPrimaryKey: true,
                        isAutoIncrement: true,
                    },
                ],
                primaryKey: {
                    columns: [
                        {
                            columnName: 'id',
                        },
                    ],
                },
            },
        },
    }, {
        forceNewTab: true,
    });
}
