"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableGridDisplay = void 0;
const dbgate_tools_1 = require("dbgate-tools");
const GridDisplay_1 = require("./GridDisplay");
class TableGridDisplay extends GridDisplay_1.GridDisplay {
    constructor(tableName, driver, config, setConfig, cache, setCache, dbinfo, displayOptions, serverVersion, getDictionaryDescription = null, isReadOnly = false, isRawMode = false) {
        super(config, setConfig, cache, setCache, driver, dbinfo, serverVersion);
        this.tableName = tableName;
        this.displayOptions = displayOptions;
        this.getDictionaryDescription = getDictionaryDescription;
        this.isRawMode = isRawMode;
        this.addAllExpandedColumnsToSelected = false;
        this.table = this.findTable(tableName);
        if (!this.table) {
            this.isLoadedCorrectly = false;
        }
        else {
            if (!this.table.columns || this.table.columns.length == 0) {
                this.isLoadedCorrectly = false;
            }
        }
        this.columns = this.getDisplayColumns(this.table, []);
        this.addFormDisplayColumns(this.getDisplayColumns(this.table, []));
        this.filterable = true;
        this.sortable = true;
        this.groupable = true;
        this.editable = !isReadOnly;
        this.supportsReload = true;
        this.baseTable = this.table;
        if (this.table && this.table.columns) {
            this.changeSetKeyFields = this.table.primaryKey
                ? this.table.primaryKey.columns.map(x => x.columnName)
                : this.table.columns.map(x => x.columnName);
        }
        if (this.config.isFormView) {
            this.addAllExpandedColumnsToSelected = true;
            this.hintBaseColumns = this.formColumns;
        }
    }
    addFormDisplayColumns(columns) {
        for (const col of columns) {
            this.formColumns.push(col);
            if (this.isExpandedColumn(col.uniqueName)) {
                const table = this.getFkTarget(col);
                if (table) {
                    const subcolumns = this.getDisplayColumns(table, col.uniquePath);
                    this.addFormDisplayColumns(subcolumns);
                }
            }
        }
    }
    findTable({ schemaName = undefined, pureName }) {
        return (this.dbinfo &&
            this.dbinfo.tables &&
            this.dbinfo.tables.find(x => x.pureName == pureName && x.schemaName == schemaName));
    }
    getDisplayColumns(table, parentPath) {
        var _a, _b;
        return (((_b = (_a = table === null || table === void 0 ? void 0 : table.columns) === null || _a === void 0 ? void 0 : _a.map(col => this.getDisplayColumn(table, col, parentPath))) === null || _b === void 0 ? void 0 : _b.map(col => {
            var _a, _b, _c;
            return (Object.assign(Object.assign({}, col), { isChecked: this.isColumnChecked(col), hintColumnNames: ((_b = (_a = this.getFkDictionaryDescription(col.isForeignKeyUnique ? col.foreignKey : null)) === null || _a === void 0 ? void 0 : _a.columns) === null || _b === void 0 ? void 0 : _b.map(columnName => `hint_${col.uniqueName}_${columnName}`)) || null, hintColumnDelimiter: (_c = this.getFkDictionaryDescription(col.isForeignKeyUnique ? col.foreignKey : null)) === null || _c === void 0 ? void 0 : _c.delimiter, isExpandable: !!col.foreignKey }));
        })) || []);
    }
    addJoinsFromExpandedColumns(select, columns, parentAlias, columnSources) {
        for (const column of columns) {
            if (this.isExpandedColumn(column.uniqueName)) {
                const table = this.getFkTarget(column);
                if (table) {
                    const childAlias = `${column.uniqueName}_ref`;
                    const subcolumns = this.getDisplayColumns(table, column.uniquePath);
                    this.addReferenceToSelect(select, parentAlias, column);
                    this.addJoinsFromExpandedColumns(select, subcolumns, childAlias, columnSources);
                    this.addAddedColumnsToSelect(select, subcolumns, childAlias, columnSources);
                }
            }
        }
    }
    addReferenceToSelect(select, parentAlias, column) {
        var _a, _b, _c, _d, _e, _f, _g;
        const childAlias = `${column.uniqueName}_ref`;
        if ((select.from.relations || []).find(x => x.alias == childAlias))
            return;
        const table = this.getFkTarget(column);
        if (table && table.primaryKey) {
            select.from.relations = [
                ...(select.from.relations || []),
                {
                    joinType: 'LEFT JOIN',
                    name: table,
                    alias: childAlias,
                    conditions: [
                        {
                            conditionType: 'binary',
                            operator: '=',
                            left: {
                                exprType: 'column',
                                columnName: column.columnName,
                                source: { name: column, alias: parentAlias },
                            },
                            right: {
                                exprType: 'column',
                                columnName: (_d = (_c = (_b = (_a = column.foreignKey) === null || _a === void 0 ? void 0 : _a.columns) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.refColumnName) !== null && _d !== void 0 ? _d : (_g = (_f = (_e = table.primaryKey) === null || _e === void 0 ? void 0 : _e.columns) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.columnName,
                                source: { name: table, alias: childAlias },
                            },
                        },
                    ],
                },
            ];
        }
    }
    getFkDictionaryDescription(foreignKey) {
        if (!foreignKey)
            return null;
        const pureName = foreignKey.refTableName;
        const schemaName = foreignKey.refSchemaName;
        const table = this.findTable({ schemaName, pureName });
        if (table && table.columns && table.columns.length > 0 && table.primaryKey) {
            const hintDescription = this.getDictionaryDescription(table);
            return hintDescription;
        }
        return null;
    }
    addHintsToSelect(select) {
        if (this.isRawMode) {
            return false;
        }
        let res = false;
        const groupColumns = this.groupColumns;
        for (const column of this.hintBaseColumns || this.getGridColumns()) {
            if (column.foreignKey) {
                if (groupColumns && !groupColumns.includes(column.uniqueName)) {
                    continue;
                }
                const table = this.getFkTarget(column);
                if (table && table.columns && table.columns.length > 0 && table.primaryKey) {
                    // const hintColumn = table.columns.find(x => x?.dataType?.toLowerCase()?.includes('char'));
                    const hintDescription = this.getDictionaryDescription(table);
                    if (hintDescription) {
                        const parentUniqueName = column.uniquePath.slice(0, -1).join('.');
                        this.addReferenceToSelect(select, parentUniqueName ? `${parentUniqueName}_ref` : 'basetbl', column);
                        const childAlias = `${column.uniqueName}_ref`;
                        select.columns.push(...hintDescription.columns.map(columnName => ({
                            exprType: 'column',
                            columnName,
                            alias: `hint_${column.uniqueName}_${columnName}`,
                            source: { alias: childAlias },
                        })));
                        res = true;
                    }
                }
            }
        }
        return res;
    }
    enrichExpandedColumns(list) {
        const res = [];
        for (const item of list) {
            res.push(item);
            if (this.isExpandedColumn(item.uniqueName))
                res.push(...this.getExpandedColumns(item));
        }
        return res;
    }
    getExpandedColumns(column) {
        const table = this.getFkTarget(column);
        if (table) {
            return this.enrichExpandedColumns(this.getDisplayColumns(table, column.uniquePath));
        }
        return [];
    }
    getFkTarget(column) {
        const { uniqueName, foreignKey, isForeignKeyUnique } = column;
        if (!isForeignKeyUnique)
            return null;
        const pureName = foreignKey.refTableName;
        const schemaName = foreignKey.refSchemaName;
        return this.findTable({ schemaName, pureName });
    }
    processReferences(select, displayedColumnInfo, options) {
        this.addJoinsFromExpandedColumns(select, this.columns, 'basetbl', displayedColumnInfo);
        if (!options.isExport && this.displayOptions.showHintColumns) {
            this.addHintsToSelect(select);
        }
    }
    createSelect(options = {}) {
        if (!this.table)
            return null;
        const select = this.createSelectBase(this.table, this.table.columns, options);
        return select;
    }
    getColumns(columnFilter) {
        return this.enrichExpandedColumns(this.columns.filter(col => (0, dbgate_tools_1.filterName)(columnFilter, col.columnName)));
    }
    getDisplayColumn(table, col, parentPath) {
        const uniquePath = [...parentPath, col.columnName];
        const uniqueName = uniquePath.join('.');
        // console.log('this.config.addedColumns', this.config.addedColumns, uniquePath);
        const res = Object.assign(Object.assign({}, col), { pureName: table.pureName, schemaName: table.schemaName, headerText: uniquePath.length == 1 ? col.columnName : `${table.pureName}.${col.columnName}`, uniqueName,
            uniquePath, isPrimaryKey: table.primaryKey && !!table.primaryKey.columns.find(x => x.columnName == col.columnName), foreignKey: table.foreignKeys &&
                table.foreignKeys.find(fk => fk.columns.length == 1 && fk.columns[0].columnName == col.columnName), isForeignKeyUnique: false });
        if (res.foreignKey) {
            const refTableInfo = this.dbinfo.tables.find(x => x.schemaName == res.foreignKey.refSchemaName && x.pureName == res.foreignKey.refTableName);
            if (refTableInfo &&
                res.foreignKey.columns.length == 1 &&
                (0, dbgate_tools_1.isTableColumnUnique)(refTableInfo, res.foreignKey.columns[0].refColumnName)) {
                res.isForeignKeyUnique = true;
            }
        }
        return res;
    }
    addAddedColumnsToSelect(select, columns, parentAlias, displayedColumnInfo) {
        for (const column of columns) {
            if (this.addAllExpandedColumnsToSelected || this.config.addedColumns.includes(column.uniqueName)) {
                select.columns.push(this.createColumnExpression(column, { name: column, alias: parentAlias }, column.uniqueName, 'view'));
                displayedColumnInfo[column.uniqueName] = Object.assign(Object.assign({}, column), { sourceAlias: parentAlias });
            }
        }
    }
    get hasReferences() {
        if (!this.table)
            return false;
        if (this.table.foreignKeys && this.table.foreignKeys.length > 0)
            return true;
        if (this.table.dependencies && this.table.dependencies.length > 0)
            return true;
        return false;
    }
}
exports.TableGridDisplay = TableGridDisplay;
