"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractSchemaNameFromComposite = exports.extractDbNameFromComposite = exports.splitCompositeDbName = exports.isCompositeDbName = exports.findDefaultSchema = void 0;
function findDefaultSchema(schemaList, dialect, schemaInStorage = null) {
    var _a;
    if (!schemaList) {
        return null;
    }
    if (schemaInStorage && schemaList.find(x => x.schemaName == schemaInStorage)) {
        return schemaInStorage;
    }
    const dynamicDefaultSchema = schemaList.find(x => x.isDefault);
    if (dynamicDefaultSchema) {
        return dynamicDefaultSchema.schemaName;
    }
    if ((dialect === null || dialect === void 0 ? void 0 : dialect.defaultSchemaName) && schemaList.find(x => x.schemaName == dialect.defaultSchemaName)) {
        return dialect.defaultSchemaName;
    }
    return (_a = schemaList[0]) === null || _a === void 0 ? void 0 : _a.schemaName;
}
exports.findDefaultSchema = findDefaultSchema;
function isCompositeDbName(name) {
    return name === null || name === void 0 ? void 0 : name.includes('::');
}
exports.isCompositeDbName = isCompositeDbName;
function splitCompositeDbName(name) {
    if (!isCompositeDbName(name))
        return null;
    const [database, schema] = name.split('::');
    return { database, schema };
}
exports.splitCompositeDbName = splitCompositeDbName;
function extractDbNameFromComposite(name) {
    return isCompositeDbName(name) ? splitCompositeDbName(name).database : name;
}
exports.extractDbNameFromComposite = extractDbNameFromComposite;
function extractSchemaNameFromComposite(name) {
    var _a;
    return (_a = splitCompositeDbName(name)) === null || _a === void 0 ? void 0 : _a.schema;
}
exports.extractSchemaNameFromComposite = extractSchemaNameFromComposite;
